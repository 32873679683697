<template>
  <div class="news3d">
    <div class="news3d_bg" v-if="Object.keys(basicData).length != 0">
      <img :src="basicData.basicImg" alt="" />
    </div>
    <div class="news3d_nav" v-if="Object.keys(basicData).length != 0">
      <div class="news3d_nav_type">
        <ul class="nav_col">
          <li
            v-for="(item, idx) in anliType"
            :key="idx"
            :class="[zp_xz == idx ? 'xz_li' : '']"
            @click="getList(item.id, idx, 1)"
          >
            {{ item.name }}
          </li>
        </ul>
        <div class="nav_sort">
          <span style="color: #999;">排序方式</span>
          <i
            class="el-icon-view"
            :style="{ color: active == 0 ? '#1342ff' : '' }"
            @click="active = 0"
          ></i>
          <i
            class="el-icon-time"
            :style="{ color: active == 1 ? '#1342ff' : '' }"
            @click="active = 1"
          ></i>
        </div>
      </div>
      <ul class="news3d_nav_list">
        <li v-for="(item, idx) in anliList" :key="idx">
          <a href="">
            <div class="case_img zz_all_img">
              <img
                :src="item.cover_image"
                alt=""
                style="width:100%;height:100%"
              />
            </div>
            <h2 class="ellipsis">{{ item.title }}</h2>
            <p class="two_ellipsis">{{ item.miaoshu }}</p>
          </a>
        </li>
      </ul>
      <div class="news3d_nav_btn centerText" @click="loadData()">
        <span
          :style="{
            background: `url(${basicData.basicTit[0].value}) 0 0 / cover`
          }"
        ></span>
        {{ basicData.basicTit[0].key }}
      </div>
    </div>
  </div>
</template>
<script>
import { getPageMsg, getAllXwData, getXwTypeData } from '@/api/api'
export default {
  components: {},
  data () {
    return {
      basicData: {},
      anliType: [],
      zp_xz: 0,
      active: 0,
      anliList: [],
      total: 0,
      page: 1,
      project: '',
      type_id: ''
    }
  },
  methods: {
    getData () {
      let that = this
      let id = sessionStorage.getItem(this.changeData() + 'id')
      that.project = sessionStorage.getItem(this.changeData() + 'project')
      getXwTypeData(1, that.project).then(res => {
        if (res.data.code == 1) {
          res.data.data.unshift({
            name: '全部文章',
            id: ''
          })
          that.anliType = res.data.data
        }
      })
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          this.basicData = res.data.data.basic
        }
      })
      that.getList('', 0)
    },
    getList (i, x, d) {
      if (d) {
        this.page = d
      }
      this.zp_xz = x
      this.type_id = i
      getAllXwData(this.page, i, this.project).then(res => {
        if (res.data.code == 1) {
          this.anliList = res.data.data.list
          this.total = res.data.data.total
          this.loading = false
        }
      })
    },
    loadData () {
      if (this.total > 6 * this.page) {
        this.page += 1
        getAllXwData(this.page, this.type_id, this.project).then(res => {
          if (res.data.code == 1) {
            this.anliList = this.anliList.concat(res.data.data.list)
          }
        })
      } else {
        this.$message.warning('已加载所有的案例。')
      }
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.news3d {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 14px;
  .news3d_bg {
    width: 100%;
    height: auto;
    img {
      width: 100%;
    }
  }
  .news3d_nav {
    padding: 80px 0;
    width: 80%;
    margin: 0 auto;
    .news3d_nav_type {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;
      font-size: 14px;
      .nav_col {
        flex: 1;
        overflow: auto;
        display: flex;
        li {
          min-width: 100px;
          padding: 14px 0;
          margin-left: 15px;
          text-align: center;
          border-radius: 20px;
          color: #1342ff;
          transition: all 0.3s ease-out;
          cursor: pointer;
        }
        li:nth-child(1) {
          margin-left: 0;
        }
        .xz_li {
          color: #fff;
          background-color: #1342ff;
        }
      }
      .nav_sort {
        display: flex;
        align-items: center;
        i {
          margin-left: 15px;
          font-size: 18px;
          cursor: pointer;
        }
      }
    }
    .news3d_nav_list {
      width: calc(100% + 20px);
      display: flex;
      flex-wrap: wrap;
      margin-top: -20px;
      margin: 0 -10px;
      li {
        width: calc(33.33% - 20px);
        margin: 0 10px;
        margin-top: 20px;
        .case_img {
          box-shadow: 0px 0px 8px rgba(169, 169, 169, 1);
          overflow: hidden;
          height: 15vw;
        }
        h2 {
          margin: 15px 0;
          font-weight: 600;
        }
        p {
          line-height: 1.5;
          font-size: 12px;
          text-indent: 2em;
        }
      }
    }
    .news3d_nav_btn {
      width: 110px;
      height: 36px;
      border-radius: 80px;
      opacity: 1;
      background: #fff;
      box-sizing: border-box;
      border: 1px solid #c7d2ff;
      margin: 0 auto;
      margin-top: 30px;
      font-size: 12px;
      cursor: pointer;
      color: #1342ff;
      span {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-left: -10px;
      }
    }
  }
}
</style>
